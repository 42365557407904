/*
If the tailwind play cdn javascript is not working, uncomment the block below and run the example

@tailwind base;
@tailwind components;
@tailwind utilities;

*/

.rbui-base-field__helper {
  display: none;
}

.toggle-field .rbui-field-label--normal {
  color: var(--banana-neutral-100);
  font-size: 11px;
}

.suggestion-list {
  top: calc(100% - 4px);
}

.suggestion-item {
  border-bottom: 1px solid #bdcee3;
  cursor: pointer;
}

.suggestion-item.selected {
  background-color: #f5f8fc;
}

.suggestion-item:hover {
  background-color: #f5f8fc;
}

.edit-button:hover {
  cursor: pointer;
  background-color: #f7f9fc;
}

.clarity-div-wrapper {
  overflow: hidden;
  position: relative;
}

.clarity-div-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px; /* Match the border radius */
  padding: 1px; /* Match the border width */
  /* background: conic-gradient(
    from -6.31deg at 50.67% 50%,
    #fe6b54 0deg,
    #dd6fb1 81deg,
    #c172ff 179.52deg,
    #e56e9a 286.2deg,
    #fe6b54 360deg
  ); */
  background: conic-gradient(
    from -6.31deg at 50.67% 50%,
    #0fc2ff 0deg,
    /* #0f9bff 81deg, */ #25cafc 179.52deg,
    /* #0f9bff 286.2deg, */ #0fc2ff 360deg
  );
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.clarity-div-container {
  position: relative;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background: white; /* Background color of the inner content */
  border-radius: 8px; /* Match the border radius */
  box-sizing: border-box;
  margin: 2px;
}

.clarity-input-shadow {
  position: relative;
}

#cocoaas_chat_wrapper .clarity_input_shadow::before {
  background: conic-gradient(
    from 78.38deg at 50% 49.23%,
    #25e9c0 -36deg,
    #0fc2ff 36deg,
    #6ae1f1 108deg,
    #6ae1f1 324deg,
    #0fc2ff 396deg
  );
}

.clarity-input-shadow::before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  background: conic-gradient(
    from 78.38deg at 50% 49.23%,
    #25e9c0 -36deg,
    #0fc2ff 36deg,
    #6ae1f1 108deg,
    #6ae1f1 324deg,
    #0fc2ff 396deg
  );
  opacity: 0.4;
  border-radius: 18px; /* Match the border radius */
  filter: blur(5px);
}

#cocoaas_chat_wrapper .clarity_input_wrapper::before {
  background: conic-gradient(
    from 78.38deg at 50% 49.23%,
    #00a782 -36deg,
    #0fc2ff 36deg,
    #00a782 108deg,
    #6ae1f1 216deg,
    #00a782 324deg,
    #0fc2ff 396deg
  );
}

.clarity-input-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px; /* Match the border radius */
  padding: 1px; /* Match the border width */
  background: conic-gradient(
    from 78.38deg at 50% 49.23%,
    #00a782 -36deg,
    #0fc2ff 36deg,
    #00a782 108deg,
    #6ae1f1 216deg,
    #00a782 324deg,
    #0fc2ff 396deg
  );
  /* opacity: 0.4; */
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.clarity-input-wrapper {
  overflow: hidden;
  position: relative;
}

.clarity-input-container {
  position: relative;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background: white; /* Background color of the inner content */
  border-radius: 8px; /* Match the border radius */
  box-sizing: border-box;
  margin: 2px;
  padding: 5px 8px;
}

.clarity-input-container input:focus {
  outline: none;
}

.clarity-markdown {
  max-width: calc(100% - 64px);
}

.clarity-markdown.clarity-ellipsis p {
  max-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.clarity-markdown p {
  max-height: 2000px;
  white-space: normal;
  overflow: visible;
  text-overflow: clip;
  transition: max-height 0.5s ease;
}

#clarity-wrapper {
  height: 100vh;
}

#clarity-close {
  position: absolute;
  background-color: white;
  top: 8px;
  right: 16px;
  width: 32px;
  height: 32px;
  display: none;
  align-items: center;
  place-content: center;
  z-index: 9999999;
}

.clarity-icon:hover {
  cursor: pointer;
}

#clarity-loading .clairty-loading-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(33, 67, 89, 0.5);
  @keyframes typingAnimation {
    0% {
      background: rgba(33, 67, 89, 0.5);
    }
    33% {
      background: #214359;
    }
    66% {
      background: rgba(33, 67, 89, 0.5);
    }
  }
  animation: typingAnimation 1.5s infinite ease-in-out;
  &:nth-child(1) {
    animation-delay: 200ms;
  }
  &:nth-child(2) {
    animation-delay: 400ms;
  }
  &:nth-child(3) {
    animation-delay: 600ms;
  }
}

.clarity-toggle-text {
  position: relative;
  display: inline-block;
}

.clarity-toggle-text span {
  visibility: hidden;
}

.clarity-toggle-text::before {
  content: "AI Assistant";
  /* linear-gradient(0deg, var(--global-main, #FFFFFF), var(--global-main, #FFFFFF)), */
  background: conic-gradient(
    from 46.53deg at 47.86% 543.75%,
    #0fc2ff 0deg,
    #25e9c0 104.4deg,
    #ffd500 193.07deg,
    #fff600 285.32deg,
    #0fc2ff 360deg
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
